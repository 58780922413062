import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import Cookies from "js-cookie";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { MdDescription, MdClose, MdBusiness } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import {
  FaEdit,
} from "react-icons/fa";
import { useTheme } from "styled-components";
import Api from "../../../services/apiService";
import { toast } from "../../../utils/toast";
import { getCount } from "../../../utils";
import Modal from "../../../components/Modal";
import Input from "../../../components/Forms/Input";
import Button from "../../../components/Forms/Button";
import { ListHeader } from "../../../components/ListHeader";
import Filter from "../../../components/Filter";
import {
  Actions,
  ButtonGroup,
  ContentGlobal,
  Form,
  Icons,
  Item,
  MessageError,
  PriorityInputContainer,
  PriorityValue,
  RowAction,
  RowInfo,
  RowItem,
  Rows,
} from "../styles";
import { useDispatch } from "react-redux";
import CheckBox from "../../../components/Forms/Checkbox";
import {
  closeLoadingModal,
  openLoadingModal,
} from "../../../store/Actions/loadingAction";
import { GoTextSize } from "react-icons/go";
import { FiEdit3 } from "react-icons/fi";

export default function Priority() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const defaultValues = {
    name: "",
    description: "",
    units: [],
    order: 0.5,
    abbreviation: "",
    default: false,
  };

  const [reload, setReload] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState();
  const [modalDisplay, setModalDisplay] = useState("none");
  const [units, setUnits] = useState([]);
  const [prioritiesCount, setPrioritiesCount] = useState(0);
  const [search, setSearch] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState({ asc: false, name: "nome_prioridade" });
  const [priorities, setPriorities] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageValue, setErrorMessageValue] = useState("");

  const schema = yup.object().shape({
    name: yup.string().required("O nome da propriedade é obrigatório"),
    description: yup.string().required("O nome da propriedade é obrigatório"),
    order: yup.number().required("Informe a ordem da prioridade"),
    abbreviation: yup.string().matches(/^[^0-9]*$/, 'Não é permitido números').required("A sigla é necessária"),
    units: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          unidade: yup.string(),
        })
      )
      .min(1, () => "Selecione pelo menos uma unidade")
      .required("Selecione pelo menos uma unidade"),
    default: yup.boolean(),
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `#333333`,
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      padding: "0.3rem",
      paddingLeft: "30px",
      margin: "5px 0px",

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      zIndex: "auto",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "none",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  function closeWindowModal() {
    reset();
    setModalDisplay("none");
    setSelectedPriority(undefined);
    setIsUpdate(false);
  }

  function handleUpdate(priority) {
    setIsUpdate(true);
    setModalDisplay("flex");
    setSelectedPriority(priority);
    setValue("order", priority.ordem || 0.5);
    setValue("name", priority.nome_prioridade);
    setValue("description", priority.desc_prioridade);
    setValue("abbreviation", priority.sigla || "");
    setValue("default", priority.padrao || false);
    setValue(
      "units",
      Array.isArray(priority.unidades)
        ? units.filter((u) => priority.unidades.includes(u.id))
        : []
    );
  }

  async function handleDelete(priority) {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.delete(`prioridades/${priority.id}`);
      toast(
        "success",
        "Sucesso",
        data?.message || "Prioridade atualizada com sucesso."
      );
      setReload(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message ||
          "Não foi possível remover a prioridade"
      );
    }
    dispatch(closeLoadingModal());
  }

  async function onSubmit(payload) {
    try {
      dispatch(openLoadingModal());
      const _payload = {
        unidades: payload.units.map((u) => u.id),
        nome_prioridade: payload.name,
        desc_prioridade: payload.description,
        ordem: payload.order,
        sigla: payload.abbreviation,
        padrao: payload.default,
      };
      await Api.post("/prioridades", _payload);
      closeWindowModal();
      reset();
      toast("success", "Sucesso", "Prioridade criado com sucesso!");
      setReload(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível criar a prioridade"
      );
    }
    dispatch(closeLoadingModal());
  }

  async function onSubmitUpdate(payload) {
    try {
      dispatch(openLoadingModal());
      const _payload = {
        unidades: payload.units.map((u) => u.id),
        nome_prioridade: payload.name,
        desc_prioridade: payload.description,
        ordem: payload.order,
        sigla: payload.abbreviation,
        padrao: payload.default,
      };
      await Api.put(`prioridades/${selectedPriority.id}`, _payload);
      closeWindowModal();
      toast("success", "Sucesso", "Prioridade atualizada com sucesso!");
      setReload(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message ||
          "Não foi possível atualizar esta prioridade."
      );
    }
    dispatch(closeLoadingModal());
  }

  async function loadUnits() {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.get("/unidades", {
        params: { status: "0" },
      });
      setUnits(data);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível buscar as unidades"
      );
    }
    dispatch(closeLoadingModal());
  }

  async function loadPriority() {
    try {
      dispatch(openLoadingModal());
      const _params = {
        limit,
        offset,
        sort: `${sort.asc ? "-" : ""}${sort.name}`,
      };
      if (search) _params.nome_prioridade = search;
      const { data, headers } = await Api.get("/prioridades", {
        params: _params,
      });
      setPriorities(data);
      const count = getCount(headers);
      setPrioritiesCount(count);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message ||
          "Não foi possível carregar todas as prioridades."
      );
    }
    dispatch(closeLoadingModal());
  }

  function isESenha(priority) {
    return priority?.sigla?.toLowerCase() === "e-senha";
  }

  function getDisabledFields() {
    return isUpdate && isESenha(selectedPriority);
  }

  const getUnitName = useCallback(
    (unitId) => {
      const unit = _.find(units, { id: unitId });
      return unit?.unidade || "";
    },
    [units]
  );

  useEffect(() => {
    loadUnits();
  }, []);

  useEffect(() => {
    loadPriority();
  }, [offset, limit, sort, search]);

  useEffect(() => {
    if (reload) {
      loadPriority();
      setReload(false);
    }
  }, [reload]);

  return (
    <ContentGlobal>
      <Filter
        title="Prioridades"
        description="Atenção: O peso da senha normal é: 0.1"
        searchProps={{
          searchableItens: [{ label: "nome" }],
          handleSearch: (term) => setSearch(term),
        }}
        paginationProps={{
          count: prioritiesCount,
          handlePageChange: (limit, offset) => {
            setLimit(limit);
            setOffset(offset);
          },
        }}
        actions={
          <Actions>
            <Button
              text="Cadastrar"
              HandleClick={() => setModalDisplay("flex")}
            />
          </Actions>
        }
      >
        <Rows>
          <ListHeader
            itens={[
              {
                label: "Nome",
                canOrder: true,
                asc: sort.name === "nome_prioridade" ? sort.asc : false,
                order: "nome_prioridade",
                isOrdered: sort.name === "nome_prioridade",
              },
              {
                label: "Ordem",
                canOrder: true,
                asc: sort.name === "ordem" ? sort.asc : false,
                order: "ordem",
                isOrdered: sort.name === "ordem",
              },
              { label: "Unidade" },
              { label: "Ações", isAction: true, large: true },
            ]}
            handleOrder={(attr) =>
              setSort((old) => ({
                name: attr,
                asc: old.name === attr ? !old.asc : false,
              }))
            }
          />
          {priorities.map((priority, index) => (
            <RowItem key={index}>
              <RowInfo>
                <Item>{priority.nome_prioridade}</Item>
              </RowInfo>
              <RowInfo>
                <Item>{priority.ordem}</Item>
              </RowInfo>
              <RowInfo>
                <Item>
                  {(priority.unidades || [])
                    .map((u) => getUnitName(u))
                    .join(", ")}
                </Item>
              </RowInfo>
              <RowAction large>
                <Icons onClick={() => handleUpdate(priority)}>
                  <FaEdit color={theme.colors.primary} />
                </Icons>
                <Icons
                  disabled={isESenha(priority)}
                  onClick={() =>
                    !isESenha(priority) ? handleDelete(priority) : undefined
                  }
                >
                  <HiOutlineTrash color={theme.colors.warning} />
                </Icons>
              </RowAction>
            </RowItem>
          ))}
        </Rows>
      </Filter>
      <Modal
        modalWidth={50}
        modalHeight={80}
        modalTitle={(isUpdate ? "Editar" : "Cadastrar") + " prioridade"}
        modalActive={modalDisplay}
        modalSetActive={() => closeWindowModal()}
      >
        <Form onSubmit={handleSubmit(isUpdate ? onSubmitUpdate : onSubmit)}>
          <Controller
            control={control}
            name="units"
            render={({ field: { onChange, value } }) => (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MdBusiness
                    style={{
                      marginLeft: "10px",
                      marginRight: "-32px",
                    }}
                  />
                  <div style={{ width: "100%" }}>
                    <Select
                      isMulti
                      isDisabled={getDisabledFields()}
                      value={value}
                      options={units}
                      onChange={onChange}
                      styles={customStyles}
                      placeholder="Selecione as unidades"
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.unidade}
                      noOptionsMessage={() => "Unidade não encontrada"}
                    />
                  </div>
                </div>
                {errors.units?.message ? (
                  <MessageError>{errors.units?.message}</MessageError>
                ) : null}
              </>
            )}
          />
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<FiEdit3 size={22} />}
                width="100"
                height="10"
                TextInput="Nome da prioridade"
                disabled={getDisabledFields()}
                value={value}
                handleChange={onChange}
                typeInput={"text"}
                errorMessage={errors?.name?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="abbreviation"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<GoTextSize size={22} />}
                width="100"
                height="10"
                TextInput="Sigla da prioridade"
                disabled={getDisabledFields()}
                value={value}
                handleChange={onChange}
                typeInput={"text"}
                errorMessage={errors?.abbreviation?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<MdDescription size={22} />}
                width="100"
                height="10"
                TextInput="Descrição da prioridade"
                disabled={getDisabledFields()}
                value={value}
                handleChange={onChange}
                typeInput={"text"}
                errorMessage={errors?.description?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="default"
            render={({ field: { onChange, value } }) => (
              <CheckBox
                disabled={getDisabledFields()}
                label="Atendimento personalizado"
                handleCheckBoxChange={onChange}
                checked={value}
                errrorMessage={errors?.default?.message}
              />
            )}
          />
          <p style={{ alignSelf: "flex-start", fontSize: 24 }}>
            Selecione a ordem da prioridade
          </p>
          <Controller
            control={control}
            name="order"
            render={({ field: { onChange, value } }) => (
              <PriorityInputContainer>
                <Input
                  hideBorder
                  value={value}
                  handleChange={onChange}
                  typeInput="range"
                  valueMax="1"
                  valueMin="0"
                  valueStep="0.1"
                  errorMessage={errors?.order?.message}
                />
                <PriorityValue>{value}</PriorityValue>
              </PriorityInputContainer>
            )}
          />
          <ButtonGroup>
            <Button
              text={isUpdate ? "Atualizar" : "Cadastrar"}
              typeButton={"submit"}
            />
          </ButtonGroup>
        </Form>
      </Modal>
    </ContentGlobal>
  );
}
