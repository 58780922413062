import { useEffect, useMemo, useRef, useState } from "react";
import io from "socket.io-client";
import { useSpeechSynthesis } from 'react-speech-kit';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useSound } from 'use-sound'
import { differenceInMinutes } from 'date-fns';
import { Timer } from "../timer";
import Logotipo from '../../../assets/Logos/LogoHeader.svg';
import RefreshLoginModal from "../../Modals/Auth";

import {
  Content,
  ContainerKeys,
  PanelKeys,
  LeaseTime,
  LastKeys,
  Pass,
  ContainerLease,
  Title,
  Key,
  Info,
  TitleInfo,
  TitleLast,
  ExtraLogo,
  ImageLast,
  KeyCurrent,
  LastKeysInfo
} from '../styles'
import { openLoadingModal, closeLoadingModal } from '../../../store/Actions/loadingAction';
import sound from '../../../assets/Sounds/AlertSound.mp3'
import { getDate } from '../../../utils';
import Api from "../../../services/apiService";
import { toast } from "../../../utils/toast";
import * as formats from '../../../utils/format';
import getBaseUrl from "../../../services/getBaseUrl";

export default function Key1({ fromKeyId }){
  const params = useParams();
  const location = useLocation();
  const senhaId = new URLSearchParams(window.location.search).get('senha_id');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ play ] = useSound(sound, {volume: .4});

  const [lastPasswords, _setLastPasswords] = useState([])
  const [showLoginModal, setShowLoginModal] = useState("none");
  const [newKey, setNewkey] = useState(false)
  const [queue, setQueue] = useState([]);
  const [toBeCalled, _setToBeCalled] = useState();
  const [clinicId, _setClinicId] = useState(params.id);
  const [clinicName, setClinicName] = useState("")
  const [myPasswordWasCalled, setMyPasswordWasCalled] = useState(false);
  const lastPasswordsRef = useRef(lastPasswords);
  const toBeCalledRef = useRef(toBeCalled);
  const clinicIdRef = useRef(clinicId);

  const tempoMedio = useMemo(() => {
    const ultimasSenhasChamadas = Array.isArray(lastPasswords)
      && lastPasswords.length > 0
      ? lastPasswords.filter((password, index) => index < 5 && password?.data_chamada && new Date(password?.data_chamada) && password?.data_geracao_senha && new Date(password?.data_geracao_senha))
      : []

    if (ultimasSenhasChamadas.length === 0) return 0;

    const tempoDeChamadaTotal = ultimasSenhasChamadas.reduce((accumulator, current) => {
      if (!current?.data_chamada) return accumulator;
      if (!(new Date(current?.data_chamada))) return accumulator
      if (!current?.data_geracao_senha) return accumulator;
      if (!(new Date(current?.data_geracao_senha))) return accumulator

      const tempoParaChamarEmMinutos = differenceInMinutes(new Date(current.data_chamada), new Date(current.data_geracao_senha))
      return accumulator + Math.abs(tempoParaChamarEmMinutos)

    }, 0)

    const tempoDeChamadSemSinal = Math.abs(tempoDeChamadaTotal)

    const tempoDeChamadaArredondado = Math.round(tempoDeChamadSemSinal)

    const media = Math.floor(tempoDeChamadaArredondado / ultimasSenhasChamadas.length)

    return media;

  }, [lastPasswords])

  function endAlertKey() {
    setNewkey(false);
    setQueue((state) => {
      if (!Array.isArray(state)) return [];
      if (state.length === 0) return [];
      return state.filter((_, index) => index > 0)
    })
  }

  const { speak, voices, speaking, cancel} = useSpeechSynthesis();
  const voice = _.first(voices?.filter(v => v.lang === 'pt-BR'));

  function setLastPasswords(passwords) {
    if (!Array.isArray(passwords)) return;
    lastPasswordsRef.current = passwords;
    _setLastPasswords(passwords);
  }

  function setToBeCalled(key) {
    // if (!key) return;
    toBeCalledRef.current = key;
    _setToBeCalled(key);
  };

  function setClinicId(id) {
    _setClinicId(id);
    clinicIdRef.current = id;
  }

  function alertNewKey(data){
    try {
      if (data && data?.id) {
        
        setNewkey(true)
        const guiche = data.nome_ambiente || "Guichê " + data.nome_guiche.split(' ')[1];
        
        const audioContext = new AudioContext();
        const audioState = audioContext.state;

        if (voice && audioState === 'running') {
          play(); // toca o som de notificação
          setTimeout(() => {
            // espera som de notificação acabar para poder chamar senha
            
            // caso o computador não tenha placa de áudio, a função vai ficar tentando chamar
            // a senha para sempre
            speak({text: `Senha ${getKeyNumber(data)} ${guiche}`, voice, rate: 0.9});

            // então adicionei um timeout com o tempo médio de chamada de senha
            // caso o status de speaking seja false, irá cancelar a chamada da fala e fechar o alerta visual de senha
            // após 3.5segundos.
            
            setTimeout(() => { 
            if (speaking) return;
              cancel()
              endAlertKey()
            }, 3500);

          }, 2500);
          return;
        }

        setTimeout(() => {
          endAlertKey()
        }, 6000);
      }

    } catch (error) {
      console.dir(error)
    }
    
  }

  function enqueue(toBeCalled, allowConflict = false) {
    setQueue((state) => {
      if (!toBeCalled?.id) return state;
      const conflictItem = _.find(state, { id: toBeCalled.id }) || null;
      if (!conflictItem?.id || allowConflict) {
        return [
          ...(
            Array.isArray(state)
              && state.length > 0
              ? state.filter((item) => item?.id !== toBeCalled.id)
              : []
          ),
          toBeCalled
        ]
      }
      return state
    })
  }

  function handleNewKeys(newestList, alreadyCalled) {
    const toBeCalled = Array.isArray(newestList)
      && newestList.length > 0
      ? _.first(newestList)
      : null;
    if (!toBeCalled) return;

    const lastCalledPassword = Array.isArray(alreadyCalled)
      && alreadyCalled.length > 0
      ? _.first(alreadyCalled)
      : null;
    if (!toBeCalled?.id || toBeCalled?.nome_ambiente || toBeCalled?.horario_encaminhamento) return;
    if (!lastCalledPassword || toBeCalled !== lastCalledPassword) {
      enqueue(toBeCalled);
    }
  }

  function checkIfPasswordWasBeenCalled() {
    const wasBeenCalled = (lastPasswordsRef.current || [])
      .some(key => key.id === senhaId);
    if (wasBeenCalled) setMyPasswordWasCalled(true);
  }

  function getPriorityName(key) {
    if (key && key?.id) {
      if (key["prioridade.nome_prioridade"])
        return key["prioridade.nome_prioridade"];
      return key.prioridade_id === null ? 'NORMAL' : 'PRIORITÁRIA';
    }
    return '';
  }

  function getEnvironmentName(key) {
    return (key && key?.id) ? key.nome_guiche : '';
  }

  function getKeyNumber(key) {
    if (key && key?.id) {
      return (
        formats.getPriorityAbbreviation(key?.numero_senha || '')
        + " "
        + formats.getKeyNumber(key?.numero_senha || '')
      );
    }
    return '';
  }

  function checkInAlreadyCalledPasswords() {
    if (myPasswordWasCalled) navigate('/e-senha', { replace: true });
  }

  function getPasswordsForScreen(numberOfScreen = 3) {
    let passwordScreens = []
    for (let i = 1; i <= numberOfScreen; i++) {
      if (lastPasswords && Array.isArray(lastPasswords) && lastPasswords[i]){
        passwordScreens.push(
          <Pass key={i}>
            <Title>{getPriorityName(lastPasswords?.[i])}</Title>
            <Key
              >{getKeyNumber(lastPasswords?.[i])}</Key>
            <Title
              >{getEnvironmentName(lastPasswords?.[i])}
            </Title>
          </Pass>
        )
      } else {
        passwordScreens.push(null)
      }
    }
    return passwordScreens;
  }

  function closeWindowModal() {
    setShowLoginModal("none");
  }

  function filtrarSenhas(senhas){
    return senhas
      .filter((item) => (item.unidade_id === clinicId) && !item.nome_ambiente)
      .slice(0, 5);
  }

  async function pegarUnidade() {
    try {
      const { data } = await Api.get(`/unidades/${params.id}`)
      setClinicName(data?.unidade || "")
    } catch (error) {
      if(error?.response?.status === 401) setShowLoginModal("flex")
      toast(
        'error',
        'Erro',
        error?.response?.message || 'Não foi possível buscar a unidade.',
      );
    }
  }

  async function validatePageAccess() {
    try {
      const token = new URLSearchParams(location.search).get("token");
      await Api.get(`/unidades/${params.id}/validate`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        }
      });
    } catch(error) {
      if(error?.response?.status === 401) setShowLoginModal("flex")
      toast(
        'error',
        'Erro',
        error?.response?.data?.message || 'Painel de senhas expirado ou inválido',
      );
    }
  }

  async function checkExpirationKey() {
    const socket = io(getBaseUrl(),{
      query: {
        unidade_id: clinicId,
      }
    });
    try {
      dispatch(openLoadingModal());
      socket.emit("flagSenhaExpirada", senhaId)
      socket.on("senhaEstaExpirada", (estaExpirada) => {
        if(estaExpirada) {
          toast(
            'error',
            'Erro',
            'Senha expirada.',
          );
          setTimeout(() => {
            navigate('/e-senha', { replace: true });
          },2000)
        }
      })
    } catch (error) {
      toast(
        'error',
        'Erro',
        error?.response?.data?.message || 'Não foi possível verificar validade da senha.',
      );
      setTimeout(() => {
        navigate('/e-senha', { replace: true });
      },2000)
    } finally {
      dispatch(closeLoadingModal());
      socket.on("disconnect", () => {
        window.location.reload();
      });
      return () => {
        socket.disconnect();
      };
    }
  }
  async function loadClinicFromKey() {
    try {
      dispatch(openLoadingModal());
      if(!clinicId){
        const { data } = await Api.get(`/senhas/${senhaId}`);
        if (!data.unidade_id || getDate(new Date()) !== getDate(data?.data_geracao_senha))
          throw Error('Senha inválida ou expirada.');
        setClinicId(data.unidade_id);
      }
      checkIfPasswordWasBeenCalled();
    } catch (error) {
      navigate('/e-senha', { replace: true });
      toast(
        'error',
        'Erro',
        error?.response?.data?.message || 'Não foi possível buscar a unidade.',
      );
    }
    dispatch(closeLoadingModal());
  }

  useEffect(() => {
    if (fromKeyId) {
      checkExpirationKey();
      loadClinicFromKey();
    } else {
      validatePageAccess();
      pegarUnidade()
    }
  }, []);
    
  useEffect(() => {
    const socket = io(getBaseUrl(),{
      query: {
        unidade_id: clinicId,
      }
    });
    const lastsPasswordsRef = lastPasswordsRef?.current || []
    socket.emit("getPasswords", clinicId)
    socket.on("updatePasswords", (data) => {
      try{
        if (!Array.isArray(data)) return;
        const newList = filtrarSenhas(data)
        if(newList.length >= lastsPasswordsRef && newList.length > 0) setLastPasswords(newList);
      } catch (error){
        console.error(error?.response?.message || 'Problema com a obtenção das senhas')
      }
    })
    socket.on("updateAndReadPasswords", (data) => {
      try{        
        if (!Array.isArray(data)) return;
        if (data.find(item => item.tipo_painel === 2)) return;
        const newList = filtrarSenhas(data)
        handleNewKeys(newList, lastsPasswordsRef);
        if(newList.length >= lastsPasswordsRef && newList.length > 0) setLastPasswords(newList);
      } catch (error){
        console.error(error?.response?.message || 'Problema com a nova senha')
      }
    });
    socket.on("senhaChamadaNovamente", (data) => {
      try{
        const _passwords = Array.isArray(lastPasswordsRef.current) ? lastPasswordsRef.current : [];
        if (!data?.id || !data?.unidade_id || data.unidade_id !== clinicId) return;
        let findKey = _.find(_passwords, { id: data.id });
        if(!findKey) { findKey = data; }
        if(data?.horario_encaminhamento || data?.nome_ambiente) return
        const oldPasswords = _passwords.filter(item => item?.id !== findKey.id);
        const newestList = [findKey, ...oldPasswords];
        enqueue(findKey, true);
        setLastPasswords(newestList);
      } catch (error){
        console.error(error?.response?.message || 'Problema com a chamada da senha novamente')
      }
    });

    socket.on("disconnect", () => {
      window.location.reload();
    });

    return () => {
      socket.disconnect();
    };

  }, []);

  useEffect(() => {
    alertNewKey(toBeCalled);
    if (fromKeyId) {
      if (toBeCalled?.id === senhaId) {
        setMyPasswordWasCalled(true);
      } else {
        checkInAlreadyCalledPasswords();
      }
    }
  }, [toBeCalled]);

  useEffect(() => {
    const newQueue = () => {
      if (!Array.isArray(queue)) return null
      if (queue.length === 0) return null;
      return queue[0]
    }
    setToBeCalled(newQueue());
  }, [queue]); 
  
  return(
    <Content>
      <RefreshLoginModal
        showLoginModal={showLoginModal}
        closeWindowModal={closeWindowModal}
        pegarUnidade={pegarUnidade}
      />
      <ExtraLogo>
        <ImageLast src={Logotipo} />
        <Timer />
      </ExtraLogo>
      <ContainerKeys>
        <PanelKeys showPanel={newKey}>
          <ContainerLease>
            <Title>SENHA ATUAL RECEPÇÃO</Title>
            <KeyCurrent 
              newestKey={newKey}>
              {getKeyNumber(newKey ? toBeCalled : lastPasswords?.[0])}
            </KeyCurrent>
            <Info>
              <TitleInfo newestKey={newKey}>
                {getPriorityName(newKey ? toBeCalled : lastPasswords?.[0])}
              </TitleInfo>
              <TitleInfo 
                newestKey={newKey}>
                {getEnvironmentName(newKey ? toBeCalled : lastPasswords?.[0])}
              </TitleInfo>
            </Info>
          </ContainerLease>
        </PanelKeys>
        {!newKey ? (
          <>
            <LeaseTime>
              <ContainerLease>
                <Title>PREVISÃO EM MINUTOS</Title>
                <Key>{tempoMedio > 0 ? tempoMedio : 1}</Key>
                <Info>
                  <Title>{clinicName}</Title>
                </Info>
              </ContainerLease>
            </LeaseTime>
            <LastKeysInfo>
              <TitleLast>ÚLTIMAS SENHAS</TitleLast>
              <LastKeys>
                {getPasswordsForScreen()}
              </LastKeys>
            </LastKeysInfo>
          </>
        ) : null}
      </ContainerKeys>
    </Content>
  )
}