import React, { useState } from "react";
import ReactSelect from "react-select";
import { Pagination, Limit } from "../Pagination";
import { Search } from "../Search";
import { Actions, Container, Header, Title } from "./styles";

export default function Filter(props) {
  const {
    children,
    searchProps,
    searchStatus,
    searchStatusUnits,
    paginationProps,
    actions,
    title,
  } = props;
  const [limit, setLimit] = useState(10);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: "200px",
      borderColor: `#333333`,
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      padding: "0.26rem",
      marginRight: "10px",

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      zIndex: "auto",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "5px",
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "none",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Actions>
        {searchStatusUnits ? (
            <ReactSelect
              styles={customStyles}
              options={[
                { label: "Todos", value: "" },
                { label: "Habilitadas", value: "0" },
                { label: "Desabilitadas", value: "2" },
              ]}
              onChange={(e) => searchStatusUnits.handleSearchStatusUnits(e.value)}
              defaultValue={{ label: "Habilitadas", value: "0" }}
            />
          ) : null}
          {searchStatus ? (
            <ReactSelect
              styles={customStyles}
              options={[
                { label: "Todos", value: "" },
                { label: "Habilitados", value: "0" },
                { label: "Desabilitados", value: "1" },
                { label: "Excluídos", value: "2" },
              ]}
              onChange={(e) => searchStatus.handleSearchStatus(e.value)}
              defaultValue={{ label: "Habilitados", value: "0" }}
            />
          ) : null}
          {searchProps ? (
            <Search
              itens={searchProps.searchableItens}
              handleSearch={searchProps.handleSearch}
            />
          ) : null}
          {paginationProps ? (
            <Limit limit={limit} onLimitChange={setLimit} />
          ) : null}
          {actions}
        </Actions>
      </Header>
      {children}
      {paginationProps && paginationProps.count !== 0 ? (
        <Pagination
          limit={limit}
          count={paginationProps.count}
          handleOnClickPage={(limit, offset) =>
            paginationProps.handlePageChange(limit, offset)
          }
        />
      ) : null}
    </Container>
  );
}
